.burgerMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20vw;
    min-height: 12vw;
    position: fixed;
    bottom: -100%;
    right: 0;
    background: rgba(255, 255, 255, 0.7);
    border-top-left-radius: 3vw;
    transition: bottom 0.3s ease-out;
}

.burger-slide-up {
    bottom: 12vw;
}